var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"my-settings__title"},[_vm._v("Change Identities")]),_c('div',{staticClass:"d-flex justify-start flex-column"},[_c('ValidationObserver',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"d-flex align-center"},[_c('label',{staticClass:"font-weight-bold mr-2 font-size-18"},[_vm._v("Email : ")]),_c('p',{staticClass:"ma-0 font-size-18"},[_vm._v(_vm._s(_vm.userState.email))]),_c('v-btn',{staticClass:"ma-2",attrs:{"outlined":"","x-small":"","fab":""},on:{"click":function($event){_vm.openEmailForm = !_vm.openEmailForm}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)],1),(_vm.openEmailForm)?_c('div',{staticClass:"my-4"},[_c('validation-provider',{attrs:{"slim":"","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Email","outlined":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}),_c('v-btn',{attrs:{"disabled":invalid || _vm.isMailOtpSent,"loading":_vm.isSending,"outlined":"","depressed":"","x-large":""},on:{"click":function($event){return _vm.sendOtp('email')}}},[_vm._v(" Send OTP ")]),(_vm.msg.length)?_c('v-alert',{staticClass:"mt-3",staticStyle:{"font-size":"16px"},attrs:{"type":_vm.alertType}},[_vm._v(" "+_vm._s(_vm.msg)+" ")]):_vm._e()],1):_vm._e(),(_vm.isMailOtpSent)?_c('div',{staticClass:"d-flex"},[_c('validation-provider',{attrs:{"slim":"","rules":"required|min:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"maxlength":"6","error-messages":errors,"label":"OTP","outlined":""},model:{value:(_vm.otp),callback:function ($$v) {_vm.otp=$$v},expression:"otp"}})]}}],null,true)}),_c('v-btn',{staticClass:"ml-6",attrs:{"disabled":_vm.otp.length < 5,"loading":_vm.isChanging,"outlined":"","depressed":"","x-large":""},on:{"click":_vm.changeEmail}},[_vm._v(" Submit ")])],1):_vm._e()]}}])}),_c('ValidationObserver',{attrs:{"slim":""}},[_c('div',{staticClass:"d-flex align-center"},[_c('label',{staticClass:"font-weight-bold mr-2 font-size-18"},[_vm._v("Phone : ")]),_c('p',{staticClass:"ma-0 font-size-18"},[_vm._v(_vm._s(_vm.userState.phoneNumber))]),_c('v-btn',{staticClass:"ma-2",attrs:{"outlined":"","x-small":"","fab":""},on:{"click":function($event){_vm.openPhoneForm = !_vm.openPhoneForm}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)],1),(_vm.openPhoneForm)?_c('div',{staticClass:"my-4"},[_c('validation-provider',{attrs:{"slim":"","rules":"required"}},[_c('ATeleInput',{staticClass:"mb-6",attrs:{"input-text":"black"},on:{"get":_vm.getValidation},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}})],1),_c('v-btn',{attrs:{"disabled":!_vm.phoneValid || _vm.isPhoneOtpSent,"outlined":"","loading":_vm.isSending,"depressed":"","x-large":""},on:{"click":function($event){return _vm.sendOtp('phone_number')}}},[_vm._v(" Send OTP ")]),(_vm.msg.length)?_c('v-alert',{staticClass:"mt-3",staticStyle:{"font-size":"16px"},attrs:{"type":_vm.alertType}},[_vm._v(" "+_vm._s(_vm.msg)+" ")]):_vm._e()],1):_vm._e(),(_vm.isPhoneOtpSent)?_c('div',{staticClass:"d-flex"},[_c('validation-provider',{attrs:{"slim":"","rules":"required|min:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"maxlength":"6","error-messages":errors,"label":"OTP","outlined":""},model:{value:(_vm.otp),callback:function ($$v) {_vm.otp=$$v},expression:"otp"}})]}}],null,false,3850990599)}),_c('v-btn',{staticClass:"ml-6",attrs:{"disabled":_vm.otp.length < 5,"loading":_vm.isChanging,"outlined":"","depressed":"","x-large":""},on:{"click":_vm.changePhone}},[_vm._v(" Submit ")])],1):_vm._e()]),_c('ValidationObserver',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"d-flex align-center"},[_c('label',{staticClass:"font-weight-bold mr-2 font-size-18"},[_vm._v("Change Password ")]),_c('v-btn',{staticClass:"ma-2",attrs:{"outlined":"","x-small":"","fab":""},on:{"click":function($event){_vm.openPasswordForm = !_vm.openPasswordForm}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)],1),(_vm.openPasswordForm)?_c('div',{staticClass:"my-4"},[_c('validation-provider',{attrs:{"rules":"required|min:6","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"placeholder":"Password","label":"Password","toggle":"","outlined":"","error-messagees":errors,"type":_vm.show ? 'text' : 'password',"append-icon":_vm.show ? 'mdi-eye' : 'mdi-eye-off'},on:{"click:append":function($event){_vm.show = !_vm.show}},model:{value:(_vm.passwordValue),callback:function ($$v) {_vm.passwordValue=$$v},expression:"passwordValue"}})]}}],null,true)}),(_vm.passwordValue.length)?_c('validation-provider',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"placeholder":"Confirm Password","label":"Confirm Password","toggle":"","hide-details":"","outlined":"","error-messagees":errors,"type":_vm.show1 ? 'text' : 'password',"append-icon":_vm.show1 ? 'mdi-eye' : 'mdi-eye-off'},on:{"click:append":function($event){_vm.show1 = !_vm.show1}},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}}),(_vm.passwordValue && _vm.confirmPassword && !_vm.validPassword)?_c('p',{staticClass:"error-alert ma-0"},[_vm._v(" Passwords must be identical ")]):_vm._e()]}}],null,true)}):_vm._e(),_c('v-btn',{staticClass:"mt-4",attrs:{"loading":_vm.isChanging,"disabled":invalid || !_vm.validPassword,"outlined":"","depressed":"","x-large":""},on:{"click":_vm.forcedPass}},[_vm._v(" Change Password ")])],1):_vm._e()]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }