/* eslint-disable prefer-destructuring */
import { watchEffect, Ref, ref, watch, computed } from '@vue/composition-api';
import { ObjectId } from 'bson';
import { Organization } from '../../../@types/organization.d';
import { fetchUserOrganizations } from '../../../api/organizationApi';

export default function useOrganizations(currentUserId: ObjectId) {
  const organizations: Ref<Organization[]> = ref([]);

  const getOrganizations = async () => {
    organizations.value = await fetchUserOrganizations(currentUserId);
  };

  watchEffect(async () => {
    getOrganizations();
  });

  const selectedOrganization: Ref<string | null> = ref(null);

  const selectedOrganizationName: Ref<string | null> = computed(() => {
    if (!selectedOrganization.value) {
      return null;
    }

    const organization = organizations.value.find(
      o => o._id.toHexString() === selectedOrganization.value
    );
    return organization ? organization.name : null;
  });

  watch(organizations, () => {
    if (organizations.value.length > 0) {
      selectedOrganization.value = organizations.value[0]._id?.toHexString();
    }
  });

  const organizationsList = computed(() => {
    return organizations.value.map(organization => ({
      name: organization.name,
      _id: organization._id?.toHexString()
    }));
  });

  return {
    organizations,
    getOrganizations,
    selectedOrganization,
    organizationsList,
    selectedOrganizationName
  };
}
