/* eslint-disable import/prefer-default-export */
import { watchEffect, Ref, ref } from '@vue/composition-api';
import { ObjectId } from 'bson';
import { Organization, OrganizationUserType } from '../../../@types/organization.d';
import { fetchInvitedOrgs, acceptOrgsInvitations } from '../../../api/organizationApi';

export function useSetupOrganizations(userEmail: Ref<string>) {
  const invitedOrgs: Ref<Organization[]> = ref([]);

  const getOrganizationsInvitations = async () => {
    invitedOrgs.value = await fetchInvitedOrgs(userEmail.value);
  };

  const acceptInvitations = async (
    orgsId: ObjectId[],
    userId: ObjectId,
    role: OrganizationUserType
  ) => {
    return acceptOrgsInvitations(orgsId, userId, role);
  };

  watchEffect(() => {
    if (!userEmail.value) return;
    getOrganizationsInvitations();
  });

  return {
    invitedOrgs,
    getOrganizationsInvitations,
    acceptInvitations
  };
}
