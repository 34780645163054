




















import { ref, watchEffect, Ref, computed } from '@vue/composition-api';
import axios from 'axios';
import { ObjectId } from 'bson';
import { useUserGetters, useUserActions, useToolActions, useDbGetters } from '@/store';

export default {
  name: 'ConfirmGoogleAuthentication',

  props: {
    code: {
      type: String,
      required: true
    }
  },

  setup(props, ctx) {
    const { getObjectId } = useUserGetters(['getObjectId']);
    const { getCalendarTokenData } = useUserActions(['getCalendarTokenData']);
    const { collection } = useDbGetters(['collection']);
    const { fetchUser } = useUserActions(['fetchUser']);
    const color = ref('grey');
    const email = ref('');
    const displayMessage = ref(`..... Connecting with ${localStorage.getItem('connectionType')}`);
    const confirmationError = ref(false);
    const googleIcon = ref(false);
    if (localStorage.getItem('connectionType') === 'google') {
      googleIcon.value = true;
    }
    const programId = computed(() => {
      return localStorage.getItem('programId');
    });
    const adkIndex = computed(() => {
      return localStorage.getItem('guideAdkIndex');
    });
    // let getStudentDoc;
    // if (localStorage.getItem('isEmployer') === 'isEmployer') {
    //   getStudentDoc = () =>
    //     collection.value!('Program').findOneAndUpdate(
    //       {
    //         _id: new ObjectId(programId.value)
    //       },
    //       {
    //         $push: {
    //           adks: { name: 'meet', status: 'complete_setup' }
    //         }
    //       }
    //     );
    // } else {
    //   getStudentDoc = () =>
    //     collection.value!('Student').findOneAndUpdate(
    //       {
    //         program_id: new ObjectId(programId.value),
    //         participant_id: getObjectId.value
    //       },
    //       {
    //         $push: {
    //           adks: { name: 'meet', completed: new Date() }
    //         }
    //       }
    //     );
    // }

    console.log(ctx.root.$route.query.code, 'code');
    watchEffect(async () => {
      if (ctx.root.$route.query.code) {
        console.log(ctx.root.$route.query, 'codeData');
        if (
          localStorage.getItem('connectionType') === 'google' &&
          ctx.root.$route.query.scope &&
          !ctx.root.$route.query.scope.includes('https://www.googleapis.com/auth/calendar')
        ) {
          ctx.root.$router.push(
            `/guide/${programId.value}/${adkIndex.value}?isScopeUnchecked=true`
          );
        } else {
          const response = await getCalendarTokenData({
            code: ctx.root.$route.query.code,
            type: localStorage.getItem('connectionType')
          });
          if (response) {
            let student;
            if (localStorage.getItem('isEmployer') === 'isEmployer') {
              student = await collection.value!('Program').findOne({
                _id: new ObjectId(programId.value),
                'adks.name': 'meet'
              });
            } else {
              student = await collection.value!('Student').findOne({
                program_id: new ObjectId(programId.value),
                participant_id: getObjectId.value,
                'adks.name': 'meet'
              });
            }
            console.log(student, 'student');
            let meetingConnection;
            if (localStorage.getItem('connectionType') === 'google') {
              meetingConnection = 'Google';
            } else if (localStorage.getItem('connectionType') === 'outlook') {
              meetingConnection = 'Outlook';
            }
            if (!student && localStorage.getItem('isEmployer') === 'isEmployer') {
              await collection.value!('Program').findOneAndUpdate(
                {
                  _id: new ObjectId(programId.value)
                },
                {
                  $push: {
                    adks: {
                      name: 'meet',
                      connection: meetingConnection,
                      status: 'complete_setup',
                      completed: new Date()
                    }
                  }
                }
              );
            } else if (student && localStorage.getItem('isEmployer') === 'isEmployer') {
              await collection.value!('Program').findOneAndUpdate(
                {
                  _id: new ObjectId(programId.value),
                  'adks.name': 'meet'
                },
                {
                  $set: {
                    'adks.$.connection': meetingConnection,
                    'adks.$.status': 'complete_setup',
                    'adks.$.completed': new Date()
                  }
                }
              );
            } else if (!student && localStorage.getItem('isEmployer') !== 'isEmployer') {
              await collection.value!('Student').findOneAndUpdate(
                {
                  program_id: new ObjectId(programId.value),
                  participant_id: getObjectId.value
                },
                {
                  $push: {
                    adks: { name: 'meet', connection: meetingConnection, completed: new Date() }
                  }
                }
              );
            } else {
              await collection.value!('Student').findOneAndUpdate(
                {
                  program_id: new ObjectId(programId.value),
                  participant_id: getObjectId.value,
                  'adks.name': 'meet'
                },
                {
                  $set: {
                    'adks.$.connection': meetingConnection,
                    'adks.$.completed': new Date()
                  }
                }
              );
            }

            await fetchUser(localStorage.getItem('apollo-token'));
            localStorage.removeItem('programId');
            localStorage.removeItem('adkIndex');
            localStorage.removeItem('connectionType');
            localStorage.removeItem('isEmployer');
            ctx.root.$router.push(`/guide/${programId.value}/${adkIndex.value}`);
          }
        }
      } else if (ctx.root.$route.query.error) {
        ctx.root.$router.push(`/portfolio`);
      }
    });

    return {
      confirmationError,
      displayMessage,
      color,
      email,
      programId,
      googleIcon
    };
  }
};
