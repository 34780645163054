







































































































































































































































































































































































































































































































































































































































































































































































































































import {
  ref,
  Ref,
  defineComponent,
  onMounted,
  reactive,
  watchEffect,
  computed
} from '@vue/composition-api';
import { useUserGetters, useDbState } from '@/store';
import { SendReferalInput, Transaction } from '@/generated/graphql';
import { GetterTypes } from '@/store/modules/auth/getters';
import Profile from '@/components/Profile.vue';
import Loading from '@/components/Loading.vue';
import axios from 'axios';
import { THIS_INVITE } from '@/services/graphql/queries';
import { SEND_INVITE } from '@/services/graphql/mutations';
import AllInvites from '@/components/portfolio/AllInvites.vue';
import AButton from '@/components/atoms/AButton.vue';
import AVideoRec from '@/components/atoms/AVideoRecord.vue';
import MTableContent from '@/components/molecules/MTableContent.vue';
import draggable from 'vuedraggable';

const {
  getObjectId: { value: getObjectId }
} = useUserGetters([GetterTypes.getObjectId]);

export default defineComponent({
  name: 'Referral',
  components: {
    AllInvites,
    Profile,
    Loading,
    AButton,
    MTableContent,
    draggable,
    AVideoRec
  },
  setup(
    _props,
    {
      root: {
        $apolloProvider: {
          defaultClient: { query, mutate }
        },
        $router
      }
    }
  ) {
    const { user } = useDbState(['user']);
    const myListDialog = ref(false);
    const templateDialog = ref(false);
    const previewDialog = ref(false);
    const InviteTableDialog = ref(false);
    const viewQuestionDialog = ref(false);
    const editInviteDialog = ref(false);
    const addQuestionDialog = ref(false);
    const resetInviteDialog = ref(false);
    const dialog = ref(false);
    const generateInviteDialog = ref(false);
    const deleteInviteDialog = ref(false);
    const changeOrderDialog = ref(false);
    const dialog3 = ref(false);
    const publishedNoti = ref(false);
    const reRecordDialog = ref(false);
    const reRecord = ref<InstanceType<typeof AVideoRec>>();
    const addQuestion = ref<InstanceType<typeof AVideoRec>>();
    const loader: Ref<typeof Loading | null> = ref(null);
    const referral: Ref<{ email: string; timestamp: Date }[]> = ref([]);
    const email = ref('');
    const inviteLink = ref('');
    const Observer = ref('');
    const validQue = ref('');
    const currentMediaUrl = ref('');
    const invites = ref([]);

    const inviteLinkList = ref([]);
    const templateList = ref([]);
    const isFetchingLinks = ref(false);
    const isFetchingTempltes = ref(false);
    const isGenerating = ref(false);
    const isVerifying = ref(false);
    const isVerified = ref(false);
    const isFetching = ref(false);
    const isPublishing = ref(false);
    const isChanging = ref(false);
    const previewUrl = ref('');
    const templateType = ref('');
    const currentQuestionId = ref('');
    const currentFormId = ref('');
    const renameInviteStep = ref('');
    const base64 = ref('');
    const newQuestion = ref('');
    const validationMsg = ref('');
    const inviteData = reactive({
      slug: '',
      title: ''
    });
    let moveEvent = reactive({
      newIndex: '',
      oldIndex: '',
      element: ''
    });

    const invalidSlug = computed(() => {
      if (inviteData.slug.length) {
        const regex = /^[a-z](-?[a-z])*$/;
        const matches = regex.exec(inviteData.slug);
        return !matches;
      }
      return '';
    });

    function previewLink(val) {
      const link = $router.resolve({
        name: 'invitation',
        params: { slug: val.slug },
        query: { isPreview: true }
      });
      console.log(link.href);
      window.open(link.href, '_blank');
    }

    function copyLink(val) {
      const url = `https://develop.pilotcity.com/invite/${val.slug}`;
      navigator.clipboard.writeText(url);
    }

    function openTemplate() {
      templateDialog.value = true;
      myListDialog.value = false;
    }

    async function verifySlug() {
      isVerified.value = false;
      isVerifying.value = true;
      const API_ENDPOINT = process.env.VUE_APP_VIDASK_SERVICE;
      try {
        const resp = await axios.get(`${API_ENDPOINT}/verify-slug?slug=${inviteData.slug}`, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        isVerifying.value = false;
        if (resp.data.data.slug_exists) {
          isVerified.value = false;
          validationMsg.value = 'Please enter unique slug';
        } else {
          isVerified.value = true;
          validationMsg.value = '';
        }
      } catch (err) {
        console.log(err);
        isVerifying.value = false;
      }
    }

    function previewTemplate(val) {
      previewDialog.value = true;
      previewUrl.value = val.share_url;
    }

    function openGenerateDialog(val) {
      templateDialog.value = false;
      generateInviteDialog.value = true;
      templateType.value = val.type;
    }

    async function fetchTemplates() {
      isFetchingTempltes.value = true;
      const API_ENDPOINT = process.env.VUE_APP_FETCH_INVITE;
      try {
        const resp = await axios.get(`${API_ENDPOINT}?user_id=${getObjectId?.toString()}`, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        templateList.value = resp.data.data;
        isFetchingTempltes.value = false;
      } catch (err) {
        console.log(err);
        isFetchingTempltes.value = false;
      }
    }

    async function fetchInviteLinks() {
      isFetchingLinks.value = true;
      const API_ENDPOINT = process.env.VUE_APP_FETCH_INVITE_LINKS;
      try {
        const resp = await axios.get(`${API_ENDPOINT}?email=${user.value?.email}`, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        inviteLinkList.value = resp.data.data;
        isFetchingLinks.value = false;
      } catch (err) {
        console.log(err);
        isFetchingLinks.value = false;
      }
    }

    async function deleteLink(val) {
      const API_ENDPOINT = process.env.VUE_APP_VIDASK_SERVICE;
      try {
        const resp = await axios.delete(`${API_ENDPOINT}/invite/delete`, {
          params: {
            slug: val.slug
          }
        });
        if (resp) {
          fetchInviteLinks();
        }
      } catch (err) {
        console.log(err);
      }
    }

    const formId = ref('');
    async function fetchTableData(val) {
      isFetching.value = true;
      const API_ENDPOINT = process.env.VUE_APP_GET_INVITE_DATA;
      try {
        const resp = await axios.get(`${API_ENDPOINT}?form_id=${val.form_id || val}`, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        if (resp.data.data) {
          invites.value = resp.data.data.questions;
          formId.value = resp.data.data.form_id;
          console.log(formId.value);
          isFetching.value = false;
        }
      } catch (err) {
        console.log(err);
        isFetching.value = false;
      }
    }

    const isPublished = ref(false);
    function openInviteEditDialog(val) {
      myListDialog.value = false;
      InviteTableDialog.value = true;
      isPublished.value = val.is_published;
      inviteData.slug = val.slug;
      fetchTableData(val);
    }

    async function generateInvite() {
      isGenerating.value = true;
      const API_ENDPOINT = process.env.VUE_APP_GEN_INVITE;

      const data = {
        ...inviteData,
        email: user.value?.email,
        template_type: templateType.value.toLowerCase()
      };

      try {
        const resp = await axios.post(API_ENDPOINT, data, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        isGenerating.value = false;
        console.log(resp);
        inviteData.title = '';
        Observer.value.reset();
        generateInviteDialog.value = false;
        if (resp.data.data) {
          InviteTableDialog.value = true;
          fetchTableData(resp.data.data);
        }
      } catch (err) {
        isGenerating.value = false;
        console.log(err);
      }
    }

    const isDeleting = ref(false);
    async function deleteInvite() {
      const API_ENDPOINT = process.env.VUE_APP_GET_DEL_INVITE;
      try {
        isDeleting.value = true;
        const resp = await axios.delete(API_ENDPOINT, {
          params: {
            question_id: currentQuestionId.value
          }
        });
        if (resp) {
          fetchTableData(currentFormId.value).then(res => {
            currentQuestionId.value = '';
            currentFormId.value = '';
            isDeleting.value = false;
            deleteInviteDialog.value = false;
          });
        }
      } catch (err) {
        console.log(err);
        isDeleting.value = false;
      }
    }

    const updatingQuestion = ref(false);
    async function updateInviteName() {
      const API_ENDPOINT = process.env.VUE_APP_GET_RENAME_INVITE;
      try {
        updatingQuestion.value = true;
        const data = {
          title: renameInviteStep.value
        };
        const res = await axios.patch(API_ENDPOINT, data, {
          params: {
            question_id: currentQuestionId.value
          }
        });
        if (res) {
          fetchTableData(currentFormId.value).then(res => {
            currentQuestionId.value = '';
            currentFormId.value = '';
            renameInviteStep.value = '';
            isDeleting.value = false;
            editInviteDialog.value = false;
            updatingQuestion.value = false;
          });
        }
      } catch (err) {
        console.log(err);
        updatingQuestion.value = false;
        editInviteDialog.value = false;
      }
    }

    watchEffect(() => {
      if (myListDialog.value === true) {
        fetchInviteLinks();
      }
      if (templateDialog.value === true) {
        fetchTemplates();
      }
      if (InviteTableDialog.value === false) {
        invites.value = [];
      }
    });

    function viewQuestion(val) {
      viewQuestionDialog.value = true;
      currentMediaUrl.value = val;
    }

    function openDeleteModal(val) {
      deleteInviteDialog.value = true;
      currentQuestionId.value = val.question_id;
      currentFormId.value = val;
    }

    function editInviteStep(val) {
      editInviteDialog.value = true;
      renameInviteStep.value = val.title;
      currentQuestionId.value = val.question_id;
      currentFormId.value = val;
    }

    function change(event) {
      changeOrderDialog.value = true;
      moveEvent = { ...event.moved };
    }
    function revert() {
      if (moveEvent) {
        invites.value[moveEvent.newIndex] = invites.value[moveEvent.oldIndex];
        invites.value[moveEvent.oldIndex] = moveEvent.element;
        changeOrderDialog.value = false;
        moveEvent = reactive({
          newIndex: '',
          oldIndex: '',
          element: ''
        });
      }
    }
    async function changeOrder() {
      const API_ENDPOINT = process.env.VUE_APP_VIDASK_SERVICE;
      try {
        isChanging.value = true;
        const res = await axios.put(
          `${API_ENDPOINT}/invite/change-order`,
          {
            question_array: invites.value
          },
          {
            params: {
              form_id: invites.value[0].form_id
            },
            headers: {
              'Content-Type': 'application/json'
            }
          }
        );
        console.log(res.data.data);
        invites.value = res.data.data.questions;
        changeOrderDialog.value = false;
        isChanging.value = false;
      } catch (err) {
        console.log(err);
      }
    }

    async function publishInvite(val, item) {
      isPublishing.value = true;
      const API_ENDPOINT = process.env.VUE_APP_VIDASK_SERVICE;
      const data = {
        slug: inviteData.slug || item.slug
      };
      if (val === 'publish') {
        data.is_published = true;
      } else {
        data.is_published = false;
      }

      try {
        const resp = await axios.post(`${API_ENDPOINT}/invite/manage-publish-status`, data, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        console.log(resp.data);
        if (resp.data.data) {
          isPublishing.value = false;
          inviteData.slug = '';
          InviteTableDialog.value = false;
          myListDialog.value = false;
          publishedNoti.value = true;
        }
      } catch (err) {
        console.log(err);
        isPublishing.value = false;
      }
    }

    function getMedia(val) {
      base64.value = val;
    }

    const isBase64 = computed(() => {
      if (base64.value) {
        return true;
      }
      return false;
    });

    function reRecordQ(val) {
      reRecord.value?.initPlayer();
      reRecordDialog.value = true;
      currentQuestionId.value = val.question_id;
      currentFormId.value = val;
    }

    function closeReRecordDialog() {
      reRecord.value?.removePlayer();
      currentQuestionId.value = '';
      reRecordDialog.value = false;
      currentFormId.value = '';
    }

    const submittingQuestion = ref(false);
    async function reRecordQuestion() {
      const API_ENDPOINT = process.env.VUE_APP_VIDASK_SERVICE;
      const data = {
        media_url: base64.value
      };
      try {
        submittingQuestion.value = true;
        const resp = await axios.patch(`${API_ENDPOINT}/invite/re-record-step`, data, {
          params: {
            question_id: currentQuestionId.value
          }
        });
        fetchTableData(currentFormId.value).then(() => {
          reRecord.value?.removePlayer();
          submittingQuestion.value = false;
          reRecordDialog.value = false;
          base64.value = '';
          currentQuestionId.value = '';
          currentFormId.value = '';
        });
      } catch (err) {
        console.log(err);
        submittingQuestion.value = false;
      }
    }

    async function resetInvite() {
      const API_ENDPOINT = process.env.VUE_APP_VIDASK_SERVICE;
      try {
        isDeleting.value = true;
        const resp = await axios.patch(`${API_ENDPOINT}/invite/reset?form_id=${formId.value}`);

        if (resp.data.data) {
          fetchTableData(resp.data.data.form_id).then(res => {
            isDeleting.value = false;
            resetInviteDialog.value = false;
          });
        }
      } catch (err) {
        console.log(err);
        isDeleting.value = false;
      }
    }

    async function addNewQuestion() {
      const API_ENDPOINT = process.env.VUE_APP_VIDASK_SERVICE;

      const data = {
        media_url: base64.value,
        title: newQuestion.value
      };

      try {
        submittingQuestion.value = true;
        const resp = await axios.post(`${API_ENDPOINT}/invite/add-step`, data, {
          params: {
            form_id: formId.value
          }
        });
        fetchTableData(formId.value).then(res => {
          addQuestion.value?.removePlayer();
          submittingQuestion.value = false;
          addQuestionDialog.value = false;
          base64.value = '';
          newQuestion.value = '';
          validQue.value.reset();
        });
      } catch (err) {
        console.log(err);
        submittingQuestion.value = false;
      }
    }

    function processQuery() {
      return query<{ transaction: Transaction }>({
        query: THIS_INVITE,
        variables: { id: getObjectId }
      }).then(({ data: { transaction } }) => {
        if (transaction && transaction.referral)
          referral.value = transaction.referral.map(item => ({
            email: item!.sentTo!,
            timestamp: item!.timestamp!
          }));
      });
    }

    const processTransfer = async () => {
      console.log('processTransfer');
      await mutate({
        mutation: SEND_INVITE,
        variables: {
          input: {
            id: getObjectId,
            email: email.value,
            name: useDbState(['user']).user.value!.firstName
          } as SendReferalInput
        }
      });
    };

    onMounted(async () => {
      await loader.value?.data?.process();
    });

    return {
      referral,
      previewLink,
      isPublished,
      openInviteEditDialog,
      invalidSlug,
      validationMsg,
      publishedNoti,
      resetInvite,
      validQue,
      addNewQuestion,
      copyLink,
      deleteLink,
      newQuestion,
      isBase64,
      getMedia,
      addQuestion,
      resetInviteDialog,
      addQuestionDialog,
      reRecordQ,
      moveEvent,
      reRecordDialog,
      closeReRecordDialog,
      submittingQuestion,
      reRecordQuestion,
      reRecord,
      change,
      changeOrder,
      revert,
      isChanging,
      updateInviteName,
      changeOrderDialog,
      publishInvite,
      isPublishing,
      updatingQuestion,
      deleteInvite,
      renameInviteStep,
      editInviteStep,
      editInviteDialog,
      isDeleting,
      viewQuestionDialog,
      deleteInviteDialog,
      openDeleteModal,
      currentMediaUrl,
      viewQuestion,
      Observer,
      invites,
      dialog,
      InviteTableDialog,
      inviteData,
      generateInvite,
      generateInviteDialog,
      isGenerating,
      isVerifying,
      isVerified,
      verifySlug,
      previewUrl,
      openGenerateDialog,
      previewTemplate,
      inviteLinkList,
      templateList,
      templateDialog,
      isFetchingLinks,
      isFetchingTempltes,
      fetchInviteLinks,
      processTransfer,
      fetchTemplates,
      openTemplate,
      processQuery,
      inviteLink,
      email,
      loader,
      myListDialog,
      previewDialog,
      dialog3,
      fetchTableData,
      isFetching,

      videoInvites: [
        {
          inviteName: 'Antioch Unified School District Invite',
          previewInvite: 159,
          chooseInvite: 149,
          inviteDateCreated: 'August 21st, 2021'
        },
        {
          inviteName: 'AUSD Invite for Kindercare',
          previewInvite: 159,
          chooseInvite: 149,
          inviteDateCreated: '5 days ago'
        },
        {
          inviteName: 'Antioch Unified prototype video invite (archive)',
          previewInvite: 159,
          chooseInvite: 149,
          inviteDateCreated: '1 days ago'
        }
      ]
    };
  }
});
