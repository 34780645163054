









































import { OrganizationUser } from '@/@types/organization.d';
import { ApiStatus } from '@/utils/const';
import { defineComponent, PropType, ref } from '@vue/composition-api';

export default defineComponent({
  name: 'StakeholdersRolesDialog',

  props: {
    user: {
      type: Object as PropType<OrganizationUser | null>,
      required: true
    },
    apiState: {
      type: String as PropType<ApiStatus>,
      required: true
    }
  },

  setup(props, { emit }) {
    const roles = ref([
      {
        label: 'Owner',
        value: 'owner'
      },
      {
        label: 'Admin',
        value: 'admin'
      },
      {
        label: 'Manager',
        value: 'manager'
      }
    ]);

    const selectedRole = ref(props.user?.role);

    const handleApplyNewRole = () => {
      emit('update-role', selectedRole.value);
    };
    return {
      handleApplyNewRole,
      roles,
      selectedRole,
      ApiStatus
    };
  }
});
