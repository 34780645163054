



























































































































































import { OrganizationUser, OrganizationUserType } from '@/@types/organization.d';
import { User } from '@/@types/user.d';
import { defineComponent, toRef, ref, Ref, computed, watch } from '@vue/composition-api';
import startCase from 'lodash/startCase';
import { ObjectId } from 'bson';
import { ApiStatus } from '@/utils/const';
import { inviteNewStakeholder, fetchUsers } from '@/api/sponsorApi';
import { acceptOrgsInvitations } from '@/api/organizationApi';
import { useDbState, useUserGetters } from '@/store';
import { GetterTypes } from '@/store/modules/auth/getters';
import debounce from 'lodash/debounce';
import useManageStakeholders from '../composables/useManageStakeholders';
import StakeholdersRolesDialog from './StakeholdersRolesDialog.vue';

export default defineComponent({
  name: 'ManageStakeholders',
  components: { StakeholdersRolesDialog },

  props: {
    organizationId: {
      type: String,
      required: false,
      default: ''
    }
  },

  setup(
    props,
    {
      root: {
        $apolloProvider: {
          defaultClient: { query, mutate }
        },
        $router
      },
      refs,
      emit
    }
  ) {
    const { stakeholders, pendingStakeholders, updateStakeholderRole, getStakeholders } =
      useManageStakeholders(toRef(props, 'organizationId'));
    const rolesDialog = ref(false);
    const selectedUser: Ref<OrganizationUser | null> = ref(null);
    const updateStakeholderApiState: Ref<ApiStatus> = ref(ApiStatus.Idle);
    const inviteeEmail: Ref<string | null> = ref(null);
    const isInvitingNewUser = ref(false);
    const selectedUserToInvite: Ref<User | null> = ref(null);
    const search: Ref<string | null> = ref(null);
    const availableUsers: Ref<User[]> = ref([]);
    const errorMessageDetails: Ref<string | null> = ref(null);

    const handleChangeRoleBtnClick = (user: OrganizationUser) => {
      rolesDialog.value = true;
      selectedUser.value = user;
    };

    const handleApplyNewRole = async (role: OrganizationUserType) => {
      if (!selectedUser.value) {
        updateStakeholderApiState.value = ApiStatus.Idle;
        return;
      }
      try {
        updateStakeholderApiState.value = ApiStatus.Loading;
        await updateStakeholderRole(
          new ObjectId(props.organizationId),
          selectedUser.value.user_id,
          role
        );
        updateStakeholderApiState.value = ApiStatus.Success;
        rolesDialog.value = false;
        selectedUser.value = null;
      } catch {
        updateStakeholderApiState.value = ApiStatus.Failure;
      }
    };

    const currentUser = computed(() => useDbState(['user']).user.value!);

    const currentUserId = computed(() => {
      const {
        getObjectId: { value: userId }
      } = useUserGetters([GetterTypes.getObjectId]);

      return userId;
    });

    const inviteStatus = ref(ApiStatus.Idle);

    const alertType = computed(() => {
      if (inviteStatus.value === ApiStatus.Success) {
        return 'success';
      }
      if (inviteStatus.value === ApiStatus.Failure) {
        return 'error';
      }
      return 'info';
    });

    const inviteAlertMessage = computed(() => {
      if (inviteStatus.value === ApiStatus.Success) {
        return 'Invite sent successfully';
      }
      if (inviteStatus.value === ApiStatus.Failure) {
        return 'Invite failed';
      }
      return null;
    });

    const resetInviteForm = () => {
      inviteStatus.value = ApiStatus.Idle;
      inviteeEmail.value = null;
      search.value = null;
      refs.inviteForm?.reset();
    };

    const addRegisteredStakeholder = async (selectedUser: User) => {
      try {
        inviteStatus.value = ApiStatus.Loading;
        await acceptOrgsInvitations(
          [new ObjectId(props.organizationId)],
          selectedUser._id,
          'admin'
        );
        inviteStatus.value = ApiStatus.Success;
        getStakeholders(props.organizationId);
        setTimeout(() => {
          resetInviteForm();
        }, 3000);
      } catch {
        inviteStatus.value = ApiStatus.Failure;
      }
    };

    const handleInviteForm = async () => {
      if (!isInvitingNewUser.value) {
        if (!selectedUserToInvite.value) {
          inviteStatus.value = ApiStatus.Failure;
          return;
        }

        try {
          const isInvited = stakeholders.value.find(
            x => x.email === selectedUserToInvite.value?.email
          );
          if (isInvited) {
            inviteStatus.value = ApiStatus.Failure;
            errorMessageDetails.value = 'User is already invited';
            return;
          }
          await addRegisteredStakeholder(selectedUserToInvite.value);
          inviteStatus.value = ApiStatus.Success;
          setTimeout(() => {
            resetInviteForm();
          }, 3000);
        } catch (error) {
          console.log(error);
          inviteStatus.value = ApiStatus.Failure;
        }
      } else {
        const valid = await refs.inviteForm?.validate();
        if (!valid || !inviteeEmail.value) {
          inviteStatus.value = ApiStatus.Failure;
          return;
        }
        inviteStatus.value = ApiStatus.Loading;

        try {
          const result = await inviteNewStakeholder(
            inviteeEmail.value,
            currentUser.value.firstName || '',
            currentUserId.value,
            new ObjectId(props.organizationId)
          );
          if (result.statusCode === 200) {
            inviteStatus.value = ApiStatus.Success;

            getStakeholders(props.organizationId);
            setTimeout(() => {
              resetInviteForm();
            }, 3000);
          } else {
            throw Error(result.error || 'invite error');
          }
        } catch {
          inviteStatus.value = ApiStatus.Failure;
        }
      }
    };

    const getPendingStakeholderStatusClass = (status: 'pending' | 'accepted' | 'rejected') => {
      if (status === 'pending') {
        return 'orange--text text--darken-3';
      }
      if (status === 'accepted') {
        return 'green--text text--darken-3';
      }
      if (status === 'rejected') {
        return 'red--text text--darken-3';
      }
      return '';
    };

    const currentUserRole = computed(() => {
      const user = stakeholders.value.find(
        (user: OrganizationUser) =>
          user.user_id.toHexString() === currentUserId.value?.toHexString()
      );

      return user?.role;
    });

    const canChangeRole = computed(() => {
      return currentUserRole.value === 'owner';
    });

    const searchUsers = async (query: string) => {
      try {
        const result = await fetchUsers(query);
        availableUsers.value = result;
      } catch {
        console.log('search error');
      }
    };

    const debouncedSearchUsers = debounce(searchUsers, 500);

    watch(search, value => {
      if (value) {
        debouncedSearchUsers(value);
      }
    });

    watch(stakeholders, value => {
      emit('update:stakeholders', value);
    });

    return {
      stakeholders,
      handleChangeRoleBtnClick,
      rolesDialog,
      selectedUser,
      startCase,
      handleApplyNewRole,
      updateStakeholderApiState,
      inviteeEmail,
      inviteStatus,
      ApiStatus,
      handleInviteForm,
      alertType,
      inviteAlertMessage,
      pendingStakeholders,
      getPendingStakeholderStatusClass,
      canChangeRole,
      selectedUserToInvite,
      search,
      isInvitingNewUser,
      availableUsers,
      errorMessageDetails
    };
  }
});
