



































































































































import { reactive, toRefs, ref, Ref, onMounted, defineComponent } from '@vue/composition-api';
import { useUserGetters, useDbActions } from '@/store';
import { ActionTypes } from '@/store/modules/db/actions';
import { GetterTypes } from '@/store/modules/auth/getters';
import { SchoolPortfolio } from '@/generated/graphql';
import { ObjectId } from 'bson';
import Loading from '@/components/Loading.vue';
import { SCHOOL_ROLE, STATE } from '@/constants/portfolio';
import { THIS_SCHOOL } from '@/services/graphql/queries';

export default defineComponent({
  name: 'SchoolID',
  components: {
    Loading
  },
  setup(
    _props,
    {
      emit,
      root: {
        $apolloProvider: {
          defaultClient: { query }
        }
      }
    }
  ) {
    const schoolStaffType = reactive({ schoolrole: SCHOOL_ROLE, stateOpts: STATE });
    const details = reactive({
      staffType: [],
      position: '',
      schoolDistrict: '',
      district: {
        streetAddress: '',
        city: '',
        state: '',
        zipcode: ''
      }
    });
    const { getObjectId } = useUserGetters([GetterTypes.getObjectId]);
    const loader: Ref<any> = ref(null);

    onMounted(() => {
      loader.value!.process();
    });

    function processQuery() {
      return query({
        query: THIS_SCHOOL,
        variables: { id: getObjectId.value }
      }).then(({ data: { schoolPortfolio: res } }) => {
        if (res)
          Object.keys(details).forEach(key => {
            if (res[key]) details[key] = res[key];
          });
      });
    }

    const { update } = useDbActions([ActionTypes.update]);

    async function save() {
      await update({
        collection: 'SchoolPortfolio',
        payload: {
          _id: getObjectId.value,
          staffType: details.staffType,
          position: details.position,
          schoolDistrict: details.schoolDistrict,
          district: details.district
        } as SchoolPortfolio,
        filter: { _id: getObjectId.value },
        options: { upsert: true }
      });
      emit('input', 'School');
    }

    return { save, ...toRefs(schoolStaffType), ...toRefs(details), processQuery, loader };
  }
});
