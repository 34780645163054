

















































































































/* eslint-disable prefer-destructuring */
import Profile from '@/components/Profile.vue';
import { ref } from '@vue/composition-api';
import { useDbState } from '@/store';
import useOrganizations from '../composables/useOrganizations';
import NewOrganizationForm from './NewOrganizationForm.vue';
import ManageStakeholders from './ManageStakeholders.vue';

export default {
  name: 'OrganizationHeader',
  components: { Profile, NewOrganizationForm, ManageStakeholders },

  setup(_props, { emit }) {
    const openNewOrgForm = ref(false);
    const sponsorRoles = ref(false);
    const manageStakeholdersDialog = ref(false);
    const { user } = useDbState(['user']);

    const {
      organizations,
      getOrganizations,
      selectedOrganization,
      organizationsList,
      selectedOrganizationName
    } = useOrganizations(user.value._id);

    const handleCreatedNewOrganization = () => {
      openNewOrgForm.value = false;
      getOrganizations();
    };

    const handleUpdatedStakeholders = value => {
      emit('update:stakeholders', value);
    };

    return {
      openNewOrgForm,
      sponsorRoles,
      user,
      organizations,
      selectedOrganization,
      handleCreatedNewOrganization,
      organizationsList,
      manageStakeholdersDialog,
      selectedOrganizationName,
      handleUpdatedStakeholders
    };
  }
};
