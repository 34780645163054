









































































































































































































































































































import { computed, defineComponent, reactive, Ref, ref, set } from '@vue/composition-api';
import { ObjectId } from 'bson';
import axios from 'axios';
import _ from 'lodash';
import { useUserGetters, useDbGetters, useDbState } from '@/store';
import imageIds from '@/generated/pilotcity_images';
import calculateProgramCompletion from '@/utils/calculateProgramCompletion';
import activitiesList from '@/views/Guide/const';

export default defineComponent({
  setup(props, context) {
    const { collection } = useDbGetters(['collection']);
    const { getObjectId } = useUserGetters(['getObjectId']);
    const dialog = ref(false);
    const { user } = useDbState(['user']);
    const cloneModal = ref(false);
    const programName = ref('');
    const currentProgram = ref('');
    const observer = ref('');
    const isVerified = ref(false);
    const role = computed(() => {
      const types = user.value?.userTypes;
      if (types?.includes('School') || types?.includes('Parent') || types?.includes('Teacher'))
        return 'Monitor';
      if (types?.includes('Employer')) return 'Manage';
      return 'none';
    });
    const students: Ref<Record<string, any>[]> = ref([]);
    const organizerPrograms: Ref<Record<string, any>> = ref([]);
    const studentPrograms: Record<string, any> = reactive([]);
    const images: Ref<Array<string>> = ref([]);
    (async () => {
      // collect students being auto-monitored by user
      const autoMonitors = await collection.value!('Monitor').find({
        owner: getObjectId.value
      });
      const autoMonitoredStudents: ObjectId[] = [];
      autoMonitors.forEach(obj => {
        autoMonitoredStudents.push(...obj.monkey.map(student => student.userID));
      });
      const studentQuery = {
        stakeholders: { $exists: true, $ne: null },
        program_id: { $exists: true, $ne: null } as Record<string, any> | ObjectId,
        $or: [
          { stakeholders: getObjectId.value },
          { stakeholders: user.value?.email },
          { participant_id: { $in: autoMonitoredStudents } }
        ]
      };
      // collect students being monitored by user
      students.value = await collection.value!('Student').find(studentQuery);
      // collect programs user is managing
      organizerPrograms.value = await collection.value!('Program').find({
        organizers: getObjectId.value,
        lastSaved: { $exists: true }
      });
      // collect student docs for programs user is participating in
      const studentDocs = await collection.value!('Student').find({
        participant_id: getObjectId.value
      });
      // collect teams the student is a part of
      const teamDocs = await collection.value!('ProgramTeam').find({
        'members._id': getObjectId.value
      });
      // collect sponsor request that are pending for the user
      const sponsorRequests = await collection.value!('SponsorRequest').find({
        participant_id: getObjectId.value,
        status: 'pending'
      });
      // get programIds for all programs
      const programIds = studentDocs.map(doc => doc.program_id);
      programIds.push(...sponsorRequests.map(doc => doc.program_id));
      const programDocs = await collection.value!('Program').find({
        _id: { $in: programIds }
      });
      // collect both studentDocs and sponsorRequests into studentPrograms
      studentPrograms.push(
        ...studentDocs.map(studentDoc => {
          const program = programDocs.find(doc => doc._id.equals(studentDoc.program_id));
          const teamDoc = teamDocs.find(
            doc =>
              doc.members.some(member => member._id.equals(studentDoc.participant_id)) &&
              doc.program_id.equals(studentDoc.program_id)
          );
          return {
            programName: program.programName,
            _id: program._id,
            adks: studentDoc.adks,
            teamAdks: teamDoc ? teamDoc.adks : []
          };
        })
      );
      studentPrograms.push(
        ...sponsorRequests.map(request => {
          const program = programDocs.find(doc => doc._id.equals(request.program_id));
          return {
            programName: program.programName,
            _id: program._id,
            awaitingSponsorship: request.status === 'pending',
            sponsorRequestId: request._id
          };
        })
      );

      // Get the needed amount of random PilotCity images
      let n = studentPrograms.length + organizerPrograms.value.length;
      images.value = new Array(n);
      let len = imageIds.length;
      const taken = new Array(n);
      while (n--) {
        const x = Math.floor(Math.random() * len);
        images.value[n] = imageIds[x in taken ? taken[x] : x];
        taken[x] = --len in taken ? taken[len] : len;
      }
    })();

    // Function to cancel sponsor requests
    async function cancelSponsorRequest(programIndex) {
      // Set loading property to document
      set(studentPrograms, programIndex, {
        ...studentPrograms[programIndex],
        cancelSponsorRequestLoading: true
      });
      await collection.value!('SponsorRequest').updateOne(
        {
          _id: studentPrograms[programIndex].sponsorRequestId,
          participant_id: getObjectId.value
        },
        {
          $set: {
            status: 'canceled'
          }
        }
      );
      // Remove document from displayed array of programs
      studentPrograms.splice(programIndex, 1);
    }

    const handleClickOnProgram = (isProgramPublished, programId) => {
      if (isProgramPublished) {
        context.root.$router.push({
          path: `/monitor?program=${programId.toString()}`
        });
      } else {
        context.root.$router.push({ path: `/guide/${programId}/0` });
      }
    };

    // clone functionality
    function openCloneModal(val) {
      currentProgram.value = val;
      cloneModal.value = true;
    }

    const isCloningProgram = ref(false);
    const isVerifying = ref(false);
    async function cloneProgram() {
      const API_ENDPOINT = process.env.VUE_APP_CLONE_PROGRAM;

      const data = {
        owner_id: user.value?._id.toString(),
        program_id: currentProgram.value._id.toString(),
        program_name: programName.value
      };
      try {
        isCloningProgram.value = true;
        const resp = await axios.post(`${API_ENDPOINT}/clone-program`, data, {
          headers: {
            'Content-Type': 'application/json'
          }
        });

        if (resp.data) {
          isCloningProgram.value = false;
          observer.value.reset();
          cloneModal.value = false;
          context.root.$router.push({
            path: `/guide/${resp.data.data._id.toString()}/0`
          });
        }
      } catch (err) {
        console.log(err);
        isCloningProgram.value = false;
      }
    }

    const validationMsg = ref('');
    const verifyProgramName = _.debounce(async () => {
      const API_ENDPOINT = process.env.VUE_APP_CLONE_PROGRAM;

      try {
        isVerifying.value = true;
        const resp = await axios.get(
          `${API_ENDPOINT}/verify-program-name?program_name=${programName.value}`,
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        );

        if (resp.data.is_unique) {
          isVerified.value = true;
          isVerifying.value = false;
          validationMsg.value = '';
        } else {
          isVerified.value = false;
          isVerifying.value = false;
          validationMsg.value = 'Enter unique program name';
        }
      } catch (err) {
        console.log(err);
        isVerifying.value = false;
      }
    }, 500);

    function routeToLatestAdk(program) {
      const adksArray: any = [...program.adks, ...program.teamAdks];
      const setupAdks = adksArray.filter(
        adk =>
          adk.completed ||
          adk.isComplete ||
          adk.discordIntegraionSkipped ||
          adk.directoryPreferences ||
          adk.timesheetDetails?.length >= 1
      );
      const mapedSetupAdks = setupAdks.map(adk => adk.name);

      const adks = activitiesList;
      let index = 0;
      for (let k = 0; k < adks.length; k++) {
        if (mapedSetupAdks.includes(adks[k])) {
          index = adks.indexOf(adks[k]);
        }
      }
      context.root.$router.push({
        name: 'guide',
        params: { programId: program._id.toString(), page: `${index}` }
      });
    }

    return {
      role,
      cloneModal,
      verifyProgramName,
      validationMsg,
      isVerified,
      isVerifying,
      observer,
      cloneProgram,
      isCloningProgram,
      currentProgram,
      openCloneModal,
      programName,
      organizerPrograms,
      studentPrograms,
      cancelSponsorRequest,
      images,
      calculateProgramCompletion,
      dialog,
      students,
      handleClickOnProgram,
      routeToLatestAdk
    };
  }
});
