import { render, staticRenderFns } from "./GeneralID.vue?vue&type=template&id=c6b605ea&scoped=true&"
import script from "./GeneralID.vue?vue&type=script&lang=ts&"
export * from "./GeneralID.vue?vue&type=script&lang=ts&"
import style0 from "./GeneralID.vue?vue&type=style&index=0&id=c6b605ea&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c6b605ea",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VAlert,VBtn,VSelect,VSkeletonLoader,VTextField})
