



































import { ref } from '@vue/composition-api';
import { useDbState } from '@/store';

import { SPONSOR_LINKS_TABLE_HEADER } from '@/constants/portfolio';
import SponsorName from '@/components/portfolio/SponsorName.vue';

export default {
  name: 'SponsorLinksTable',
  components: { SponsorName },
  props: {
    items: { required: true, type: Array },
    sponsors: { required: true, type: Array }
  },

  setup() {
    const formatUses = item => {
      const claims = Object.keys(item.claimedBy).length;
      const total = Object.keys(item.tokenPool).length;
      return `${claims} / ${total}`;
    };

    const copy = str => {
      navigator.clipboard.writeText(str);
    };

    return {
      header: ref(SPONSOR_LINKS_TABLE_HEADER),
      user: useDbState(['user']).user,
      formatUses,
      copy
    };
  }
};
