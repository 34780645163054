var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"balance-view__entire-body"},[_c('v-data-table',{attrs:{"headers":_vm.header,"items":_vm.items},scopedSlots:_vm._u([{key:"item.sponsor",fn:function(ref){
var item = ref.item;
return [_c('SponsorName',{attrs:{"item":item}})]}},{key:"item.shareCode",fn:function(ref){
var item = ref.item;
return [_c('v-row',{staticClass:"pl-3 align-center"},[_vm._v(" "+_vm._s(("https://www.pilotcity.com/sponsor/" + (item.shareCode)))+" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-3",attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.copy(("https://www.pilotcity.com/sponsor/" + (item.shareCode)))}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v("Copy link")])])],1)]}},{key:"item.uses",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatUses(item)))]}},{key:"item.revoke",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"red","outlined":"","depressed":"","small":""},on:{"click":function($event){return _vm.$emit('revoke', item.shareCode)}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-delete")]),_vm._v("Delete")],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }