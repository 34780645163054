
























































































































import {
  Ref,
  reactive,
  ref,
  toRefs,
  onMounted,
  defineComponent,
  computed,
  toRef
} from '@vue/composition-api';
import { useUserGetters, useDbActions, useRealmAppState, useDbGetters } from '@/store';
import axios from 'axios';
import Loading from '@/components/Loading.vue';
import { GetterTypes } from '@/store/modules/auth/getters';
import { ActionTypes } from '@/store/modules/db/actions';
import { User } from '@/generated/graphql';
import { CITIZEN_TYPES } from '@/constants/portfolio';
import ATeleInput from '@/components/atoms/ATeleInput.vue';
import { THIS_GENERAL_USER } from '@/services/graphql/queries';
import { ObjectId } from 'bson';
import { useSetupOrganizations } from '../../composables/useSetupOrganizations';

export default defineComponent({
  name: 'GeneralID',
  components: {
    Loading,
    ATeleInput
  },
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },
  setup(
    _props,
    {
      emit,
      root: {
        $apolloProvider: {
          defaultClient: { query }
        }
      }
    }
  ) {
    const { getObjectId } = useUserGetters([GetterTypes.getObjectId]);
    // Page Setup
    const AVAILABLE_IDS = ref(CITIZEN_TYPES);
    const show = ref(false);
    const showPassword = ref(false);
    const user = reactive({
      firstName: '',
      lastName: '',
      phoneNumber: '',
      formattedNumber: '',
      email: '',
      userTypes: [],
      confirm_email: ''
    });
    const isEmailAvailable = ref(false);
    const showConfirmEmail = ref(false);
    const isPhoneAvailable = ref(false);
    const loader: Ref<any> = ref(null);
    const isOpenDialog = ref(false);
    const isPhoneValid = ref(false);
    const isSetting = ref(false);
    const passwordValue = ref('');
    const isChecked = ref(false);
    const msg = ref('');
    const isGuardian = ref(false);

    if (localStorage.getItem('is-guardian')) {
      isGuardian.value = true;
      const obj = { text: 'Parent', value: 'Parent' };
      user.userTypes.push(obj.value);
    }

    const selectedOrganizations = ref(null);

    const { invitedOrgs, acceptInvitations } = useSetupOrganizations(toRef(user, 'email'));

    const availableOrganizations = computed(() => {
      return invitedOrgs.value.map(x => {
        return {
          _id: x._id?.toHexString(),
          name: x.name
        };
      });
    });

    const isValid = computed(() => {
      if (passwordValue.value.length >= 6 && isPhoneAvailable.value) {
        return true;
      }
      if (isEmailAvailable.value) {
        return true;
      }
      return false;
    });

    const validEmail = computed(() => {
      if (user.email === user.confirm_email && isPhoneAvailable.value) {
        return true;
      }
      if (isEmailAvailable.value) {
        return true;
      }
      return false;
    });

    function setPasswordValue(val) {
      passwordValue.value = val;
      isOpenDialog.value = false;
      isChecked.value = true;
    }

    function getValidation(val) {
      if (val && val.countryCallingCode && val.formatted && val.valid) {
        user.formattedNumber = `+${val.countryCallingCode}${val.formatted}`;
      } else {
        user.formattedNumber = '';
      }
      isPhoneValid.value = val.valid;
    }

    const phoneValid = computed(() => {
      if (isPhoneValid.value && user.phoneNumber) {
        return true;
      }
      return false;
    });

    const isPhoneCheck = computed(() => {
      const substring = '+1';
      const substring1 = '+';

      if (user.phoneNumber.includes(substring) || user.phoneNumber.includes(substring1)) {
        return false;
      }

      return true;
    });

    function processQuery() {
      return query<{ user: User }>({
        query: THIS_GENERAL_USER,
        variables: { id: getObjectId.value }
      }).then(({ data: { user: userRes } }) => {
        // Set Query result when loaded
        if (userRes.email) {
          isEmailAvailable.value = true;
        }
        if (userRes.phoneNumber) {
          isPhoneAvailable.value = true;
        }
        Object.keys(user).forEach(key => {
          if (userRes && userRes[key]) user[key] = userRes[key];
          if (userRes && userRes.phoneNumber) user.phoneNumber = userRes.phoneNumber.substring(2);
        });
      });
    }

    async function setPassword() {
      try {
        isSetting.value = true;
        const data = {
          type: 'link_email',
          email: user.email,
          password: passwordValue.value
        };
        const res = await axios.post(`${process.env.VUE_APP_AUTH0}/add-identity`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        });
        console.log(res.data);
        if (res.data) {
          emit('input', user.userTypes);
        }
        isSetting.value = false;
      } catch (error) {
        console.log(error);
        msg.value = error?.response?.data?.error?.description;
        isSetting.value = false;
      }
    }

    async function setMobile() {
      try {
        isSetting.value = true;
        const data = {
          type: 'link_phone',
          phone_number: user.formattedNumber
        };
        const res = await axios.post(`${process.env.VUE_APP_AUTH0}/add-identity`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        });

        if (res.data) {
          emit('input', user.userTypes);
        }
        isSetting.value = false;
      } catch (error) {
        console.log(error);
        msg.value = error?.response?.data?.error?.description;
        isSetting.value = false;
      }
    }

    // Upload Functionality
    const { collection } = useDbGetters(['collection']);
    const { update } = useDbActions([ActionTypes.update]);
    const { app } = useRealmAppState(['app']);

    async function removeGuardianFlag() {
      try {
        const API_ENDPOINT = process.env.VUE_APP_AGREEMENT_FLAG;

        const res = await axios.patch(
          `${API_ENDPOINT}?student_id=${localStorage.getItem('is-guardian')}`
        );

        if (res) {
          localStorage.removeItem('is-guardian');
        }
      } catch (error) {
        console.log(error);
      }
    }

    async function save() {
      if (localStorage.getItem('is-guardian')) {
        await removeGuardianFlag();
      }
      msg.value = '';
      let validateRecord = null;
      if (isPhoneAvailable.value && !isEmailAvailable.value) {
        validateRecord = await collection.value!('User').findOne({
          email: user.email
        });
      } else if (isEmailAvailable.value && user.phoneNumber && !isPhoneAvailable.value) {
        validateRecord = await collection.value!('User').findOne({
          phoneNumber: user.formattedNumber
        });
      }

      if (!validateRecord) {
        const updateUserResponse = await update({
          collection: 'User',
          payload: {
            _id: getObjectId.value,
            firstName: user.firstName,
            lastName: user.lastName,
            phoneNumber: user.formattedNumber,
            userTypes: user.userTypes,
            email: user.email.toLowerCase()
          } as User,
          filter: { _id: getObjectId.value },
          options: { upsert: true }
        });

        if (updateUserResponse) {
          if (isPhoneAvailable.value && !isEmailAvailable.value) {
            setPassword();
          } else if (isEmailAvailable.value && user.phoneNumber && !isPhoneAvailable.value) {
            setMobile();
          } else {
            emit('input', user.userTypes);
          }
        }

        if (getObjectId.value) {
          acceptInvitations(
            selectedOrganizations.value?.map(x => new ObjectId(x)),
            getObjectId.value,
            'admin' // TODO: get role from Transaction collection
          );
        }
      } else {
        msg.value = 'Email or Phone already exists';
      }
    }
    onMounted(() => {
      loader.value!.process();
    });

    return {
      AVAILABLE_IDS,
      removeGuardianFlag,
      ...toRefs(user),
      save,
      processQuery,
      loader,
      isEmailAvailable,
      isPhoneAvailable,
      isOpenDialog,
      isSetting,
      setPassword,
      setPasswordValue,
      passwordValue,
      setMobile,
      isValid,
      isChecked,
      msg,
      getValidation,
      validEmail,
      showConfirmEmail,
      isPhoneValid,
      phoneValid,
      show,
      showPassword,
      isPhoneCheck,
      isGuardian,
      availableOrganizations,
      selectedOrganizations,
      invitedOrgs
    };
  }
});
