









































































































































































import { ref } from '@vue/composition-api';

import { useDbState } from '@/store';
import { OrganizationUser } from '@/@types/organization.d';
import OrganizationHeader from './OrganizationHeader.vue';
import { fetchTokens } from '../../../api/organizationApi';

export default {
  name: 'SponsorHome',

  components: {
    OrganizationHeader
  },

  setup(_, ctx) {
    const { user } = useDbState(['user']);

    const sponsorRoles = ref(false);

    const tokensCount = ref(0);

    const handleUpdatedStakeholders = async (stakeholders: OrganizationUser[]) => {
      const stakeholdersIds = stakeholders.map(stakeholder => stakeholder.user_id);
      tokensCount.value = await fetchTokens(stakeholdersIds);
    };

    const handleClickBuyTokens = () => {
      ctx.emit('changeTab', 'payment');
    };
    return {
      user,
      sponsorRoles,
      tokensCount,
      handleUpdatedStakeholders,
      handleClickBuyTokens
    };
  }
};
