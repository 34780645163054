




























































































import {
  Ref,
  reactive,
  ref,
  toRefs,
  defineComponent,
  watchEffect,
  computed
} from '@vue/composition-api';
import { useUserGetters, useDbActions, useDbState, useUserActions } from '@/store';
import axios from 'axios';
import { GetterTypes } from '@/store/modules/auth/getters';
import Loading from '@/components/Loading.vue';
import ATeleInput from '@/components/atoms/ATeleInput.vue';
import { User } from '@/generated/graphql';
import { CITIZEN_TYPES } from '@/constants/portfolio';

const { getObjectId } = useUserGetters([
  GetterTypes.getUser,
  GetterTypes.getObjectId,
  GetterTypes.getId
]);

interface TypeItem {
  text: string;
  value: string;
}

export default defineComponent({
  name: 'SettingsName',
  components: {
    Loading,
    ATeleInput
  },
  props: {
    value: {
      type: Array as () => TypeItem[],
      default: () => []
    }
  },
  setup(_props, { emit }) {
    const AVAILABLE_IDS = ref(CITIZEN_TYPES);
    const showConfirmEmail = ref(false);
    const isEmailAvailable = ref(false);
    const isPhoneAvailable = ref(false);
    const loader: Ref<any> = ref(null);
    const isSetting = ref(false);
    const isPhoneValid = ref(false);
    const passwordValue = ref('');
    const isChecked = ref(false);
    const msg = ref('');
    const showPassword = ref(false);
    const show = ref(false);

    const { user: userState } = useDbState(['user']);
    const { fetchUser } = useUserActions(['fetchUser']);

    // Invoke Query
    const user = reactive({
      firstName: '',
      lastName: '',
      phoneNumber: '',
      formattedNumber: '',
      email: '',
      userTypes: [],
      confirm_email: ''
    });
    Object.keys(user).forEach(key => {
      if (key) {
        user[key] = userState.value![key];
      }
      if (userState.value && userState.value.phoneNumber)
        user.phoneNumber = userState.value.phoneNumber.substring(2);
    });
    watchEffect(() => {
      if (userState.value.email) {
        isEmailAvailable.value = true;
      }
      if (userState.value.phoneNumber) {
        isPhoneAvailable.value = true;
      }
    });

    const validEmail = computed(() => {
      if (user.email === user.confirm_email && isPhoneAvailable.value) {
        return true;
      }
      if (isEmailAvailable.value) {
        return true;
      }
      return false;
    });

    const isValid = computed(() => {
      if (passwordValue.value.length >= 6 && isPhoneAvailable.value) {
        return true;
      }
      if (isEmailAvailable.value) {
        return true;
      }
      return false;
    });

    const phoneValid = computed(() => {
      if (isPhoneValid.value && user.phoneNumber) {
        return true;
      }
      if (!isPhoneValid.value && !user.phoneNumber) {
        return true;
      }
      return false;
    });

    const isPhoneCheck = computed(() => {
      const substring = '+1';
      const substring1 = '+';

      if (user.phoneNumber.includes(substring) || user.phoneNumber.includes(substring1)) {
        return false;
      }

      return true;
    });

    async function setPassword() {
      try {
        isSetting.value = true;
        const data = {
          type: 'link_email',
          email: user.email,
          password: passwordValue.value
        };
        const res = await axios.post(`${process.env.VUE_APP_AUTH0}/add-identity`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        });
        console.log(res.data);
        if (res.data) {
          emit('input', user.userTypes);
          isEmailAvailable.value = true;
        }
        isSetting.value = false;
        msg.value = '';
      } catch (error) {
        console.log(error);
        msg.value = error?.response?.data?.error?.description;
        isSetting.value = false;
      }
    }

    async function setMobile() {
      try {
        isSetting.value = true;
        const data = {
          type: 'link_phone',
          phone_number: user.formattedNumber
        };
        const res = await axios.post(`${process.env.VUE_APP_AUTH0}/add-identity`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        });
        if (res.data) {
          emit('input', user.userTypes);
          isPhoneAvailable.value = true;
        }
        isSetting.value = false;
        msg.value = '';
      } catch (error) {
        console.log(error);
        msg.value = error?.response?.data?.error?.description;
        isSetting.value = false;
      }
    }

    function getValidation(val) {
      if (val && val.countryCallingCode && val.formatted && val.valid) {
        user.formattedNumber = `+${val.countryCallingCode}${val.formatted}`;
      } else {
        user.formattedNumber = '';
      }
      isPhoneValid.value = val.valid;
    }
    // Upload Functionality
    const { update } = useDbActions(['update']);
    async function save() {
      await update({
        collection: 'User',
        payload: {
          _id: getObjectId.value,
          firstName: user.firstName,
          lastName: user.lastName,
          phoneNumber: user.formattedNumber,
          userTypes: user.userTypes,
          email: user.email
        } as User,
        filter: { _id: getObjectId.value },
        options: { upsert: true }
      }).then(res => {
        fetchUser(localStorage.getItem('apollo-token'));
        if (res) {
          if (isPhoneAvailable.value && !isEmailAvailable.value) {
            setPassword();
          } else if (isEmailAvailable.value && user.phoneNumber && !isPhoneAvailable.value) {
            setMobile();
          } else {
            emit('input', user.userTypes);
          }
        }
      });
    }

    return {
      save,
      AVAILABLE_IDS,
      isPhoneCheck,
      ...toRefs(user),
      loader,
      isEmailAvailable,
      isPhoneAvailable,
      isSetting,
      setPassword,
      passwordValue,
      setMobile,
      isValid,
      isChecked,
      msg,
      getValidation,
      validEmail,
      showConfirmEmail,
      isPhoneValid,
      phoneValid,
      show,
      showPassword
    };
  }
});
