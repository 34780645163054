

















































































import { computed, ref, Ref } from '@vue/composition-api';
import { defer } from 'rxjs';
import { retry } from 'rxjs/operators';

import { useDbActions, useStripeActions, useDbState } from '@/store';
import Profile from '@/components/Profile.vue';

export default {
  name: 'Payment',

  components: {
    Profile
  },

  setup(_, ctx) {
    const { user } = useDbState(['user']);
    const sponsorName = ref('');
    const { getProductInfos } = useDbActions(['getProductInfos']);
    const PRICE_IDS =
      process.env.VUE_APP_PAYMENT_ENV === 'development'
        ? [
            // OLD
            // 'price_1HXAFALnkQGEgDQnGsjqbuuM', // student
            // 'price_1HXABLLnkQGEgDQnwzzt63Np', // family
            // 'price_1HXA9ZLnkQGEgDQn9uaE7Hm2', // group
            // 'price_1HXAD3LnkQGEgDQnqxQabHZO', // class
            // 'price_1HXACELnkQGEgDQnWpGByUE0' // teacher

            // NEW
            'price_1Lv25LLnkQGEgDQnPdW6vbww', // student
            'price_1Lv25nLnkQGEgDQnfDQBcWmQ', // family
            'price_1Lv26LLnkQGEgDQnHj1g1e19', // group
            'price_1Lv2GcLnkQGEgDQncFw5LXU7' // class
            // 'price_1HVJuELnkQGEgDQnV1dDldCH' // teacher
          ]
        : [
            // OLD
            // 'price_1HXEkkLnkQGEgDQnedtEFh3p',
            // 'price_1HXEkcLnkQGEgDQnVKGG37Yj',
            // 'price_1KoekVLnkQGEgDQn4LEznMVj',
            // 'price_1KoemRLnkQGEgDQnohSIpDbo',
            // 'price_1HXEkfLnkQGEgDQntVYb3iFX'

            // NEW
            'price_1HXEkkLnkQGEgDQnrZfn3mYi',
            'price_1HXEkcLnkQGEgDQnhMwkBNDQ',
            'price_1Lv24ZLnkQGEgDQnu8PXghTN',
            'price_1Lv2FuLnkQGEgDQnT1Hh1hbx'
            // 'price_1HXEkfLnkQGEgDQnted58hU9'
          ];
    const ColorCode = {
      'Per Student': 'green',
      'Per Family': 'orange',
      'Per Group': 'purple',
      'Per Class': 'blue',
      'Per Teacher': 'pink'
    };
    const purchaseOptions: Ref<
      {
        title: string;
        desc: string | null;
        priceId: string;
        price: number | null;
        color: any;
        quantity: number;
        unitLabel: string | null;
      }[]
    > = ref([]);
    getProductInfos({ priceId: PRICE_IDS }).then(result => {
      purchaseOptions.value = result.body.map(res => ({
        title: res.product.name,
        desc: res.product.description,
        priceId: res.price.id,
        price: res.price.unit_amount,
        color: ColorCode[res.product.name],
        quantity: 0,
        unitLabel: res.product.unit_label
      }));
    });
    const selectedProduct = computed(() => purchaseOptions.value.filter(opt => opt.quantity > 0));
    const { createCheckoutSession, createInvoice } = useStripeActions([
      'createCheckoutSession',
      'createInvoice'
    ]);
    const invoiceState = ref({
      state: '',
      message: ''
    });
    const checkout = async () => {
      defer(() =>
        createCheckoutSession({
          lineItems: selectedProduct.value,
          cancelUrl: 'http://pilotcity.com/sponsor'
        })
      )
        .pipe(retry(3))
        .subscribe(result => {
          if (result.error) {
            invoiceState.value.state = 'error';
            invoiceState.value.message = result.error.message!;
          }
        });
    };
    // Handle Invoicing

    const sendInvoice = async () => {
      try {
        const invoice = await createInvoice({ lineItems: selectedProduct.value });
        // eslint-disable-next-line no-unreachable
        if (invoice?.openInvoice?.hosted_invoice_url)
          window.location.href = invoice?.openInvoice?.hosted_invoice_url;
        ctx.root.$router.push(invoice?.openInvoice?.hosted_invoice_url);
        invoiceState.value.state = 'success';
        invoiceState.value.state = 'success';
      } catch (err) {
        invoiceState.value.state = 'error';
        invoiceState.value.message = err;
      }
      // handle invoice logic
    };
    return {
      sponsorName,
      purchaseOptions,
      checkout,
      selectedProduct,
      sendInvoice,
      invoiceState,
      user
    };
  }
};
