





















































































































import { reactive, toRefs, ref, Ref, onMounted, defineComponent } from '@vue/composition-api';

import { useUserGetters, useDbActions } from '@/store';
import { ActionTypes } from '@/store/modules/db/actions';
import { GetterTypes } from '@/store/modules/auth/getters';
import { TeacherPortfolio } from '@/generated/graphql';
import { ObjectId } from 'bson';
import Loading from '@/components/Loading.vue';
import { STATE } from '@/constants/portfolio';
import { THIS_TEACHER } from '@/services/graphql/queries';

export default defineComponent({
  name: 'TeacherID',
  components: {
    Loading
  },
  setup(
    _props,
    {
      emit,
      root: {
        $apolloProvider: {
          defaultClient: { query }
        }
      }
    }
  ) {
    const { getObjectId } = useUserGetters([GetterTypes.getObjectId]);
    const formOpt = reactive({ stateOpts: STATE });
    const details = reactive({
      schoolDistrict: '',
      schoolName: '',
      subjects: [] as string[],
      schools: {
        streetAddress: '',
        city: '',
        state: '',
        zipcode: ''
      }
    });

    const loader: Ref<any> = ref(null);

    function remove(item: string) {
      details.subjects.splice(details.subjects.indexOf(item), 1);
      details.subjects = [...details.subjects];
    }
    onMounted(() => {
      loader.value!.process();
    });

    function processQuery() {
      return query<{ teacherPortfolio: TeacherPortfolio }>({
        query: THIS_TEACHER,
        variables: { id: getObjectId.value }
      }).then(({ data: { teacherPortfolio: res } }) => {
        if (res)
          Object.keys(details).forEach(key => {
            if (res[key]) details[key] = res[key];
          });
      });
    }

    const { update } = useDbActions([ActionTypes.update]);

    async function save() {
      await update({
        collection: 'TeacherPortfolio',
        payload: {
          _id: getObjectId.value,
          schoolDistrict: details.schoolDistrict,
          schoolName: details.schoolName,
          subjects: details.subjects,
          schools: details.schools
        } as TeacherPortfolio,
        filter: { _id: getObjectId.value },
        options: { upsert: true }
      });
      emit('input', 'Teacher');
    }

    return {
      ...toRefs(details),
      ...toRefs(formOpt),
      save,
      processQuery,
      loader,
      remove
    };
  }
});
