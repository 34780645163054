


























import { ref, watchEffect } from '@vue/composition-api';

import { GetterTypes } from '@/store/modules/auth/getters';
import { useDbActions, useUserGetters, useDbState } from '@/store';
import { User } from '@/generated/graphql';
import Loading from '@/components/Loading.vue';

export default {
  name: 'SettingsNotification',
  components: { Loading },
  setup() {
    const { getObjectId } = useUserGetters([
      GetterTypes.getUser,
      GetterTypes.getObjectId,
      GetterTypes.getId
    ]);
    const { user: userState } = useDbState(['user']);
    const sponsorName = ref('');

    watchEffect(() => {
      if (userState.value?.sponsorName) {
        sponsorName.value = userState.value?.sponsorName;
      } else if (userState.value?.firstName && userState.value?.lastName) {
        sponsorName.value = `${userState.value?.firstName} ${userState.value?.lastName}`;
      }
    });
    const { update } = useDbActions(['update']);
    async function save() {
      await update({
        collection: 'User',
        payload: {
          sponsorName: sponsorName.value
        } as User,
        filter: { _id: getObjectId.value },
        options: { upsert: true }
      });
    }

    return { save, sponsorName };
  }
};
