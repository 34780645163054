var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"my-settings__title"},[_vm._v("General")]),_c('div',{staticClass:"d-flex justify-center flex-column align-center"},[_c('div',{ref:"loader",staticClass:"my-id__wrapper",staticStyle:{"width":"100%"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"outlined":"","label":"First Name"},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Last Name","outlined":""},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}})]}}],null,true)}),_c('validation-provider',{attrs:{"slim":"","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"disabled":_vm.isEmailAvailable,"label":"Email","outlined":""},on:{"input":function($event){_vm.showConfirmEmail = true;
            _vm.showPassword = true;}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}),(!_vm.isEmailAvailable && _vm.showConfirmEmail)?_c('validation-provider',{attrs:{"slim":""}},[_c('v-text-field',{attrs:{"label":"Confirm Email","outlined":""},model:{value:(_vm.confirm_email),callback:function ($$v) {_vm.confirm_email=$$v},expression:"confirm_email"}}),(_vm.email && _vm.confirm_email && !_vm.validEmail)?_c('p',{staticClass:"error-alert"},[_vm._v(" Email must be identical ")]):_vm._e()],1):_vm._e(),(_vm.isPhoneAvailable && !_vm.isEmailAvailable && _vm.showPassword)?_c('validation-provider',{attrs:{"slim":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"placeholder":"Password","label":"Password","toggle":"","outlined":"","error-messagees":errors,"type":_vm.show ? 'text' : 'password',"append-icon":_vm.show ? 'mdi-eye' : 'mdi-eye-off'},on:{"click:append":function($event){_vm.show = !_vm.show}},model:{value:(_vm.passwordValue),callback:function ($$v) {_vm.passwordValue=$$v},expression:"passwordValue"}})]}}],null,true)}):_vm._e(),_c('validation-provider',{attrs:{"slim":"","rules":"required"}},[_c('ATeleInput',{staticClass:"mb-6",attrs:{"disabled":_vm.isPhoneAvailable,"input-text":"black"},on:{"get":_vm.getValidation},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}})],1),_c('validation-provider',{attrs:{"slim":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.AVAILABLE_IDS,"chips":"","label":"Citizen Type","multiple":"","outlined":""},model:{value:(_vm.userTypes),callback:function ($$v) {_vm.userTypes=$$v},expression:"userTypes"}})]}}],null,true)}),_c('Loading',{attrs:{"callback":_vm.save},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var saving = ref.loading;
            var process = ref.process;
return [_c('v-btn',{attrs:{"disabled":invalid || !_vm.isValid || !_vm.validEmail || !_vm.phoneValid || !_vm.isPhoneCheck,"loading":saving || _vm.isSetting,"outlined":"","depressed":"","x-large":""},on:{"click":process}},[_vm._v(" Save ")])]}}],null,true)}),(_vm.msg)?_c('v-alert',{staticClass:"mt-3",staticStyle:{"font-size":"16px"},attrs:{"type":"error"}},[_vm._v(_vm._s(_vm.msg))]):_vm._e()],1)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }