











































































































































import { ref, Ref, watch } from '@vue/composition-api';

import { useUserGetters, useDbState } from '@/store';
import { MY_TOKEN_CUSTOMER } from '@/services/graphql/queries';

import Loading from '@/components/Loading.vue';
import Profile from '@/components/Profile.vue';

import Sponsor from '@/views/Sponsor/components/Home.vue';
import MyPrograms from './views/MyPrograms.vue';
import CitizenId, { components as citizenComponents } from './views/CitizenID.vue';
import Balance from './views/Balance.vue';
import Payment from './views/Payment.vue';
import Referral from './views/Referral.vue';
import Settings from './views/Settings.vue';

export default {
  name: 'Portfolio',

  components: {
    MyPrograms,
    CitizenId,
    Balance,
    Payment,
    Referral,
    Loading,
    Settings,
    Profile,
    Sponsor
  },

  setup(_props, context) {
    const { user } = useDbState(['user']);
    const tickets = ref(0);
    const loader: Ref<any> = ref(null);
    const currentTab: Ref<
      | 'my-programs'
      | 'citizen-id'
      | 'balance'
      | 'payment'
      | 'referral'
      | 'loading'
      | 'settings'
      | 'profile'
      | 'sponsor'
    > = ref(
      [
        'my-programs',
        'citizen-id',
        'balance',
        'payment',
        'referral',
        'loading',
        'settings',
        'profile',
        'sponsor'
      ].includes(context.root._route.params.component)
        ? context.root._route.params.component
        : 'my-programs'
    );
    const currentProfile: Ref<keyof typeof citizenComponents> = ref('general-id');
    const IDs = ref({
      Employer: 'purple',
      Organizer: 'purple',
      Student: 'green',
      Teacher: 'pink',
      School: 'blue',
      Parent: 'yellow'
    });

    watch(currentTab, newTab => {
      context.root.$router.replace({
        params: {
          component: newTab,
          profile: newTab === 'citizen-id' ? currentProfile.value : null
        }
      });
    });
    watch(currentProfile, newProfile => {
      context.root.$router.replace({
        params: {
          profile: newProfile
        }
      });
    });
    const id = useUserGetters(['getId']).getId;
    console.log(id);
    const processQuery = () =>
      context.root.$apolloProvider.defaultClient
        .query({
          query: MY_TOKEN_CUSTOMER,
          variables: { id: id.value }
        })
        .then(({ data: { tokens } }) => {
          tickets.value = tokens.length;
        });
    const clickTab = id => {
      currentTab.value = id;
    };
    const clickProfile = (profile: string) => {
      currentTab.value = 'citizen-id';
      currentProfile.value = `${profile.toLowerCase()}-id` as keyof typeof citizenComponents;
    };
    watch(
      user,
      newUser => {
        const types = newUser?.userTypes;
        if (types?.includes('School') || types?.includes('Parent')) currentTab.value = 'payment';
        else if (types?.includes('Teacher')) {
          // currentTab.value = 'invite';
        } else currentTab.value = 'my-programs';
        loader.value!.process();
      },
      { deep: true }
    );

    const showSponsor = process.env.VUE_APP_SHOW_SPONSOR === 'true';

    return {
      currentTab,
      currentProfile,
      clickProfile,
      IDs,
      tickets,
      loader,
      clickTab,
      processQuery,
      user,
      snackbar: true,
      showSponsor
    };
  }
};
