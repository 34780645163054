/* eslint-disable prefer-destructuring */
import { Ref, ref, watch } from '@vue/composition-api';
import { ObjectId } from 'bson';
import {
  OrganizationUser,
  OrganizationUserType,
  OrganizationInvite
} from '../../../@types/organization.d';
import {
  fetchStakeholders,
  updateStakeholder,
  fetchStakeholdersInvitation
} from '../../../api/organizationApi';

export default function useManageStakeholders(organizationId: Ref<string>) {
  const stakeholders: Ref<OrganizationUser[]> = ref([]);
  const pendingStakeholders: Ref<OrganizationInvite[]> = ref([]);

  const getStakeholders = async (organizationId: string) => {
    stakeholders.value = await fetchStakeholders(new ObjectId(organizationId));
    pendingStakeholders.value = await fetchStakeholdersInvitation(new ObjectId(organizationId));
  };

  const updateStakeholderRole = async (
    organizationId: ObjectId,
    stakeholderId: ObjectId,
    newRole: OrganizationUserType
  ) => {
    const newOrg = await updateStakeholder(organizationId, stakeholderId, newRole);

    if (!newOrg) {
      console.log('throwing');
      throw new Error();
    }

    const newUsers = newOrg.users;
    const newUser = newUsers.find(x => x.user_id.toHexString() === stakeholderId.toHexString());

    const existing = stakeholders.value.find(
      x => x.user_id.toHexString() === newUser.user_id.toHexString()
    );
    existing.role = newUser.role;
    existing.updated = newUser.updated;

    stakeholders.value.splice(stakeholders.value.indexOf(existing), 1, existing);
  };

  watch(
    organizationId,
    () => {
      getStakeholders(organizationId.value);
    },
    {
      immediate: true
    }
  );

  return {
    stakeholders,
    updateStakeholderRole,
    getStakeholders,
    pendingStakeholders
  };
}
