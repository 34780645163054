
































































































































































































































































































































































import { computed, onMounted, ref, Ref } from '@vue/composition-api';
import { ObjectID } from 'bson';

import { Token, User } from '@/generated/graphql';
import { useUserGetters, useDbState, useDbGetters } from '@/store';
import Profile from '@/components/Profile.vue';
import SponsorLinksTable from '@/components/portfolio/SponsorLinksTable.vue';
import { MY_TOKEN_OWNER, USERS } from '@/services/graphql/queries';
import { TRANSFER_TOKENS } from '@/services/graphql/mutations';
import BalanceView from '../BalanceView.vue';

export default {
  name: 'Balance',
  components: { Profile, BalanceView, SponsorLinksTable },
  setup(
    _props,
    {
      root: {
        $apolloProvider: {
          defaultClient: { query, mutate }
        }
      }
    }
  ) {
    const colors = ['red', 'orange', 'blue', 'purple', 'pink', 'yellow'];

    const transferState = ref(false);
    const sponsorState = ref(false);
    // Token Management
    const tokens: Ref<Token[]> = ref([]);
    const originalOwners: Ref<Pick<User, '_id' | 'firstName' | 'lastName'>[]> = ref([]);

    // Create Sponsorship Link Management
    const { functions } = useDbGetters(['functions']);
    const { collection } = useDbGetters(['collection']);
    const autoMonitor = ref(true);
    const oneTimeUse = ref(true);
    const sponsorId = ref('');
    const sponsorQuantity = ref(0);
    const validateQty = ref(0);
    const sponsorLinksTableItems: Ref<any[]> = ref([]);
    const sponsorshipLink = ref('');
    const sponsorWelcomeMessage = ref('');
    const shareLinkDialog = ref(false);
    // Transfer Management
    const transferEmail = ref('');
    const transferQuantity = ref(0);
    const processTransferError = ref(null);
    const loading = ref(false);

    const id = useUserGetters(['getId']).getId;

    const isDecimal = computed(() => {
      const regex = /^([0-9]+)$/;
      const matches = regex.exec(sponsorQuantity.value.toString());
      return !!matches;
    });

    const isValid = computed(() => {
      if (
        sponsorQuantity.value > 0 &&
        sponsorId.value.length &&
        sponsorQuantity.value <= validateQty.value
      ) {
        return true;
      }
      return false;
    });

    const fetchTokens = () => {
      collection.value!('Tokens')
        .find({
          newOwner_id: new ObjectID(id.value!)
        })
        .then(async res => {
          tokens.value = res;
          const tokenOwners = res.map(token => ({ _id: token.owner_id }));
          if (tokenOwners.length)
            originalOwners.value = (
              await query({
                query: USERS,
                variables: { userQueryInputs: tokenOwners }
              })
            ).data.users;
        });
    };

    // UI Management
    const modOriginalOwners = computed(() =>
      originalOwners.value.map(owner => ({
        ...owner,
        color: colors[Math.floor(Math.random() * (colors.length - 1))]
      }))
    );

    const updateSponsorQuantity = () => {
      collection.value!('Tokens')
        .find({
          owner_id: new ObjectID(sponsorId.value),
          newOwner_id: new ObjectID(id.value!)
        })
        .then(async res => {
          sponsorQuantity.value = res.length;
          validateQty.value = res.length;
        });
    };

    const formatSponsorLink = (code: string) => `https://www.pilotcity.com/sponsor/${code}`;

    const fetchSponsorLinks = () => {
      collection.value!('SponsorLinks')
        .find({
          createdBy: new ObjectID(id.value!)
        })
        .then(links => {
          sponsorLinksTableItems.value = links;
          fetchTokens();
        });
    };

    const sponsorName = item =>
      item.sponsorName ? item.sponsorName : `${item.firstName} ${item.lastName}`;

    const createSponsorshipLink = async () => {
      let sponsorTokens;
      await collection.value!('Tokens')
        .find({
          owner_id: new ObjectID(sponsorId.value),
          newOwner_id: new ObjectID(id.value!)
        })
        .then(res => {
          sponsorTokens = res;
        });
      const tokenIds = sponsorTokens.map(token => token._id).slice(0, sponsorQuantity.value);
      const sponsor = originalOwners.value.filter(owner => owner._id === sponsorId.value)[0];
      await functions.value
        .callFunction(
          'createSponsorLink',
          id.value,
          sponsorQuantity.value,
          autoMonitor.value,
          tokenIds
        )
        .then(res => {
          sponsorshipLink.value = formatSponsorLink(res?.data?.shareCode);
          fetchSponsorLinks();
        });
      sponsorWelcomeMessage.value = `Want to build employer projects to win internships? ${sponsorName(
        sponsor
      )} sponsors you to participate in the PilotCity flagship program. Browse employers using the following sponsorship link & code: ${
        sponsorshipLink.value
      }. Welcome aboard! We're rooting for you.`;
      shareLinkDialog.value = true;
      sponsorId.value = '';
      sponsorQuantity.value = 0;
    };

    const revokeToken = (code: string) => {
      functions.value.callFunction('revokeLink', id.value, code).then(() => {
        fetchSponsorLinks();
      });
    };

    const copy = str => {
      navigator.clipboard.writeText(str);
    };

    const processTransfer = async () => {
      loading.value = true;
      try {
        if (transferQuantity.value > tokens.value.length) {
          throw new Error('You tried to send more tokens than your account holds.');
        }
        if (transferQuantity.value > 100) {
          throw new Error('Please transfer your tokens in smaller batches (< 100 per batch).');
        }
        await mutate({
          mutation: TRANSFER_TOKENS,
          variables: {
            recipientEmail: transferEmail.value,
            senderId: id.value,
            tokenIds: tokens.value.map(token => token._id).slice(0, transferQuantity.value)
          }
        });
      } catch (e) {
        console.error(e);
        processTransferError.value = e.message;
      } finally {
        loading.value = false;
      }
    };

    onMounted(() => {
      fetchSponsorLinks();
    });

    const errors = '';
    return {
      sponsorshipLink,
      isDecimal,
      isValid,
      sponsorWelcomeMessage,
      dialog6: false,
      shareLinkDialog,
      sponsorQuantity,
      estimatedNumber: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
      transferState,
      sponsorState,
      tokens,
      originalOwners,
      autoMonitor,
      oneTimeUse,
      copy,
      sponsorName,
      createSponsorshipLink,
      sponsorLinksTableItems,
      updateSponsorQuantity,
      showCopiedLinkTooltip: ref(false),
      showCopiedMessageTooltip: ref(false),
      revokeToken,
      modOriginalOwners,
      transferEmail,
      processTransferError,
      loading,
      processTransfer,
      transferQuantity,
      user: useDbState(['user']).user,
      sponsorId,
      sponsorNameExamples: ['Arroyo High School', 'San Lorenzo Unified School District'],
      errors
    };
  }
};
