




































import { ref } from '@vue/composition-api';
import { Organization } from '@/@types/organization.d';
import { createNewOrganization } from '@/api/organizationApi';
import { ApiStatus } from '@/utils/const';
import { useDbState } from '@/store';

export default {
  name: 'NewOrganizationForm',

  setup(props, ctx) {
    const { user } = useDbState(['user']);

    const form = ref({
      name: ''
    });

    const status = ref(ApiStatus.Idle);

    const handleCreateNewOrganization = async e => {
      const valid = await ctx.refs.newOrgForm?.validate();
      if (!valid) {
        status.value = ApiStatus.Failue;
        return;
      }

      status.value = ApiStatus.Loading;

      const newOrganization: Organization = {
        name: form.value.name,
        created: new Date(),
        updated: new Date(),
        created_by: user.value?._id,
        users: [
          {
            user_id: user.value?._id,
            role: 'owner',
            created: new Date(),
            updated: new Date()
          }
        ]
      };

      try {
        const response = await createNewOrganization(newOrganization);
        status.value = ApiStatus.Success;
        form.value.name = '';
        ctx.refs.newOrgForm?.reset();
        ctx.emit('create-new-organization', response);
      } catch {
        status.value = ApiStatus.Failue;
      }
    };

    return {
      form,
      handleCreateNewOrganization,
      status,
      ApiStatus
    };
  }
};
