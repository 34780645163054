import { render, staticRenderFns } from "./ChangeIdentities.vue?vue&type=template&id=7524c203&scoped=true&"
import script from "./ChangeIdentities.vue?vue&type=script&lang=ts&"
export * from "./ChangeIdentities.vue?vue&type=script&lang=ts&"
import style0 from "./ChangeIdentities.vue?vue&type=style&index=0&id=7524c203&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7524c203",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VAlert,VBtn,VIcon,VTextField})
