/* eslint-disable import/prefer-default-export */
import { ObjectId } from 'bson';
import type { User } from '../@types/user.d';
import { useDbGetters } from '../store';

const { collection } = useDbGetters(['collection']);
const { functions } = useDbGetters(['functions']);

export const inviteNewStakeholder = async (
  email: string,
  inviterName: string,
  inviterId: ObjectId | null,
  orgId: ObjectId
) => {
  return functions.value?.callFunction('sendInviteOrgStakeholder', {
    email,
    inviterName,
    inviterId,
    orgId
  });
};

export const fetchUsers = async (searchQuery: string): Promise<User[]> => {
  return collection.value!('User').aggregate([
    {
      $match: {
        email: {
          $regex: `${searchQuery}`
        }
      }
    }
  ]);
};
