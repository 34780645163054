




























































































































































































































































import {
  Ref,
  reactive,
  toRefs,
  ref,
  onMounted,
  defineComponent,
  watch,
  computed
} from '@vue/composition-api';

import { useUserGetters, useDbActions, useDbState } from '@/store';
import { ActionTypes } from '@/store/modules/db/actions';
import { GetterTypes } from '@/store/modules/auth/getters';
import { SendReferalInput, StudentPortfolio } from '@/generated/graphql';
import Loading from '@/components/Loading.vue';
import {
  GRADE_LEVEL,
  SUPER_GENDER,
  ETHNICITY,
  GUARDIAN,
  HOME_LANG,
  STATE
} from '@/constants/portfolio';
import { THIS_STUDENT } from '@/services/graphql/queries';
import { SEND_GUARDIAN_REFFERAL } from '@/services/graphql/mutations';
import { ObjectId } from 'bson';
import axios from 'axios';
import _ from 'lodash';
import AAutocomplete from '@/components/atoms/AAutocomplete.vue';

export default defineComponent({
  name: 'StudentID',
  components: {
    Loading,
    AAutocomplete
  },
  setup(
    _props,
    {
      emit,
      root: {
        $apolloProvider: {
          defaultClient: { query, mutate }
        }
      }
    }
  ) {
    const { getObjectId } = useUserGetters([GetterTypes.getObjectId]);

    const search = ref('');
    const isSearchingSchool = ref(false);
    const isSearchingSchoolDistrict = ref(false);
    const isSearchingStreet = ref(false);
    const isEdit = ref(false);
    const formOpt = reactive({
      gradeLevel: GRADE_LEVEL,
      superGender: SUPER_GENDER,
      ethnicityCulture: ETHNICITY,
      guardianRelationship: GUARDIAN,
      homeLanguageOpts: HOME_LANG,
      stateOpts: STATE
    });
    // Interactions
    const menu = ref(false);

    // to be labeled
    const responses = reactive({
      school: {
        name: '',
        district: ''
      },
      guardian: {
        email: '',
        relationship: '',
        firstName: '',
        lastName: ''
      },
      home: {
        streetAddress: '',
        language: [],
        city: '',
        state: '',
        zipcode: ''
      },
      date: '',
      ethnicity: [],
      gender: '',
      grade: '',
      invited: false
    });

    function setSchoolValue(e) {
      if (e.target) {
        responses.school.name = e.target.value;
      } else if (e.name) {
        responses.school.name = `${e.name}, ${e.formatted_address}`;
      }
    }

    function setDistrictValue(e) {
      if (e.target) {
        responses.school.district = e.target.value;
      } else if (e.name) {
        responses.school.district = e.name;
      }
    }

    const loader: Ref<any> = ref(null);
    const emailSent = ref(false);

    async function fetchPlaces(val) {
      try {
        isSearchingStreet.value = true;
        const resp = await axios.get(process.env.VUE_APP_GOOGLE_PLACES_DETAILS, {
          params: {
            place_id: val.place_id
          },
          headers: {
            'Content-Type': 'application/json'
          }
        });
        if (resp.data.data.result && resp.data.data.result.address_components) {
          responses.home.state = resp.data.data.result.address_components.find(
            itm => itm.types[0] === 'administrative_area_level_1'
          ).short_name;
          responses.home.zipcode = resp.data.data.result.address_components.find(
            itm => itm.types[0] === 'postal_code'
          ).long_name;
          responses.home.city = resp.data.data.result.address_components.find(
            itm => itm.types[0] === 'locality'
          ).long_name;
        }
        isSearchingStreet.value = false;
      } catch (err) {
        console.log(err);
        isSearchingStreet.value = false;
      }
    }

    function setStreetValue(e) {
      if (e.target) {
        responses.home.streetAddress = e.target.value;
      } else if (e.name) {
        responses.home.streetAddress = e.formatted_address;
        fetchPlaces(e);
      }
    }

    function processQuery() {
      return query<{ studentPortfolio: StudentPortfolio }>({
        query: THIS_STUDENT,
        variables: { id: getObjectId.value }
      }).then(({ data: { studentPortfolio: res } }) => {
        if (res)
          Object.keys(responses).forEach(key => {
            if (res[key]) responses[key] = res[key];
          });
      });
    }
    const { user } = useDbState(['user']);
    function sendInvite() {
      return mutate({
        mutation: SEND_GUARDIAN_REFFERAL,
        variables: {
          email: responses.guardian.email,
          id: getObjectId.value,
          name: `${user.value!.firstName} ${user.value!.lastName}`
        } as SendReferalInput
      }).then(() => {
        emailSent.value = true;
      });
    }
    const alert = ref(false);
    const { update } = useDbActions([ActionTypes.update]);
    async function save() {
      await update({
        collection: 'StudentPortfolio',
        payload: {
          _id: getObjectId.value,
          school: responses.school,
          guardian: responses.guardian,
          home: responses.home,
          date: responses.date,
          ethnicity: responses.ethnicity,
          gender: responses.gender,
          grade: responses.grade,
          invited: responses.invited
        } as StudentPortfolio,
        filter: { _id: getObjectId.value },
        options: { upsert: true }
      });
      alert.value = true;
      setTimeout(function () {
        emit('input', 'Student');
      }, 2000);
    }
    onMounted(() => {
      loader.value!.process();
    });

    return {
      ...toRefs(formOpt),
      ...toRefs(responses),
      menu,
      setSchoolValue,
      setDistrictValue,
      setStreetValue,
      emit,
      save,
      processQuery,
      loader,
      sendInvite,
      emailSent,
      search,
      isSearchingSchool,
      isSearchingSchoolDistrict,
      isSearchingStreet,
      fetchPlaces,
      isEdit,
      alert
    };
  }
});
