/* eslint-disable import/prefer-default-export */
import { ObjectId } from 'bson';
import flatten from 'lodash/flatten';
import uniqBy from 'lodash/uniqBy';
import {
  Organization,
  OrganizationUser,
  OrganizationUserType,
  OrganizationInvite
} from '../@types/organization.d';
import { useDbGetters } from '../store';

const { collection } = useDbGetters(['collection']);

interface Referral {
  sentTo: string;
  timestamp: Date;
}

export const fetchUserOrganizations = async (userId: ObjectId) => {
  return collection.value!('Organization').aggregate([
    {
      $match: {
        users: {
          $elemMatch: {
            user_id: {
              $in: [userId]
            }
          }
        }
      }
    }
  ]);
};

export const fetchInvitedOrgs = async (userEmail: string): Promise<Organization[]> => {
  const invitations = await collection.value!('Transaction').aggregate([
    {
      $match: {
        referral: {
          $elemMatch: {
            sentTo: userEmail
          }
        }
      }
    },
    {
      $project: {
        orgId: 1
      }
    }
  ]);

  const orgs = await collection.value!('Organization').aggregate([
    {
      $match: {
        _id: {
          $in: invitations.map(inv => inv.orgId)
        }
      }
    }
  ]);

  return orgs as Organization[];
};

export const createNewOrganization = async (organization: Organization) => {
  return collection.value!('Organization').insertOne(organization);
};

export const updateStakeholder = async (
  organizationId: ObjectId,
  stakeholderId: ObjectId,
  newRole: OrganizationUserType
): Promise<Organization> => {
  const updatedStakeholder = {
    $set: {
      'users.$.role': newRole,
      'users.$.updated': new Date()
    }
  };

  return collection.value!('Organization').findOneAndUpdate(
    {
      _id: organizationId,
      'users.user_id': stakeholderId
    },
    updatedStakeholder,
    {
      returnNewDocument: true
    }
  );
};

export const fetchStakeholdersInvitation = async (
  organizationId: ObjectId
): Promise<OrganizationInvite[]> => {
  const invitations = await collection.value!('Transaction').aggregate([
    {
      $match: {
        orgId: organizationId
      }
    },
    {
      $project: {
        referral: 1
      }
    }
  ]);

  const referralCollection = uniqBy(
    flatten(invitations.map(x => x.referral)),
    'sentTo'
  ) as Referral[];

  const users = await collection.value!('User').aggregate([
    {
      $match: {
        email: {
          $in: referralCollection.map(x => x.sentTo)
        }
      }
    },
    {
      $project: {
        email: 1
      }
    }
  ]);

  const usersEmails = users.map(x => x.email) as [];

  return referralCollection.map(x => {
    const invite: OrganizationInvite = {
      email: x.sentTo,
      created: x.timestamp,
      status: usersEmails.includes(x.sentTo) ? 'accepted' : 'pending'
    };
    return invite;
  });
};

export const fetchStakeholders = async (organizationId: ObjectId) => {
  const data = await collection.value!('Organization').aggregate([
    {
      $match: {
        _id: organizationId
      }
    },
    {
      $limit: 1
    },
    {
      $project: {
        users: 1
      }
    },
    {
      $lookup: {
        from: 'User',
        foreignField: '_id',
        localField: 'users.user_id',
        as: 'userDetails'
      }
    }
  ]);

  const orgData = data[0];
  const { users } = orgData;
  const usersDetails = orgData.userDetails;

  return users.map((user: any) => {
    const userDetails = usersDetails.find(item => item._id.equals(user.user_id));
    const orgUser: OrganizationUser = {
      ...user,
      firstName: userDetails.firstName,
      lastName: userDetails.lastName,
      email: userDetails.email
    };

    return orgUser;
  });
};

export const acceptOrgsInvitations = async (
  orgsId: ObjectId[],
  userId: ObjectId,
  role: OrganizationUserType
) => {
  const addNewUser = {
    $push: {
      users: {
        user_id: userId,
        role,
        created: new Date(),
        updated: new Date()
      }
    }
  };

  return collection.value!('Organization').updateMany(
    {
      _id: {
        $in: orgsId
      }
    },
    addNewUser
  );
};

export const fetchTokens = async (userIds: ObjectId[]): Promise<number> => {
  const tokensCount = await collection.value!('Tokens').aggregate([
    {
      $match: {
        newOwner_id: {
          $in: userIds
        }
      }
    },
    {
      $count: 'count'
    }
  ]);

  return tokensCount[0].count as number;
};
